.input_container {
  border: 1px solid  #aaaaaa;
  width: 30%;
}

.input_containers {
  border: 1px solid  #aaaaaa;
  width: 45%;
}

.custom-file-upload {
  display: inline-block;
  padding: 5px 15px;
  border: 1px solid #293846;
  background-color: #f3f3f4;
  cursor: pointer;
  font-size: 13px; 
  line-height: 1;
  font-family: sans-serif;
}

.custom-file-upload:hover {
  background-color: #e0e0e0;
  border-color: #293846;
  font-size: 13px; 
  line-height: 1;
}
